<!-- 订单修改9fface -->
<template>
<div class="editExamine">
  <search-bar-new
    :placeholder-text="$t('请输入订单编号/申请人')"
    :str="searchStr"
    @input="onInput"
    @search="onRefresh">
  </search-bar-new>
  <div  class="totalCount">{{$t('共')}}{{ totalCount }}{{$t('条数据')}}</div>
  <List ref="list" :fetchApi="fetchApi">
    <template v-slot:default="{ item }">
      <div class="common-block editItem" @click="goDetail(item)">
        <div v-if="item.carOrderMaterial && item.carOrderMaterial.seriesName" class="orderStatus-l common-label">{{ `${item.carOrderMaterial?.seriesName || ''} ${item.carOrderMaterial?.modelName || ''}` }}</div>
          <div class="common-label">
            <div class="common-label-t">{{$t('订单编号：')}}</div>
            <div class="common-label-c">{{ item.appOrderId }}</div>
          </div>
          <div class="common-label">
            <div class="common-label-t">{{$t('销售门店：')}}</div>
            <div class="common-label-c">{{ item.deliveryDealerName }}</div>
          </div>
          <div class="common-label">
            <div class="common-label-t">{{$t('申请人：')}}</div>
            <div class="common-label-c">{{ item.applyUserName }}</div>
          </div>
          <div class="common-label spacial-label">
            <div class="common-label-t">{{$t('申请时间：')}}</div>
            <div class="common-label-c">{{ item.createTime }}</div>
            <div class="amount"><span content="¥"></span>{{ `${formatRMB(item.totalAmount)}` }}</div>
          </div>
          <van-checkbox
            v-model="item.checked"
            shape="square"
            class="check-item"
            @change="onClick($event, item)">
          </van-checkbox>
        </div>
    </template>
  </List>
  <div class="action-bar">
    <div class="left">
      <span @click="cleanDistribution">{{$t('取消')}}</span>
      <span @click="onAllChecked">{{$t('全选')}}</span>
    </div>
    <div class="right">
      <van-button square color="#EED484" @click="onExamine">
        {{$t('订单修改审核')}}{{ examineIds.length ? `(${examineIds.length})` : '' }}
      </van-button>
    </div>
  </div>
  <van-dialog
    v-model="showDialog"
    class="authDialog"
    :title="$t('批量审核')"
    show-cancel-button
    confirmButtonColor="#EED484"
    :confirm-button-text="$t('确定')"
    :before-close="beforeClose">
    <van-form ref="dialogForm" label-width='65px'>
      <van-field
        v-model="batchParams.result"
        :label="$t('审核结果')"
        required
        :rules="[{ required: true, message: $t('请选择审核结果')}]">
        <template #input>
          <van-radio-group v-model="batchParams.result" direction="horizontal">
            <van-radio name="0">{{$t('驳回')}}</van-radio>
            <van-radio name="1">{{$t('通过')}}</van-radio>
          </van-radio-group>
        </template>
      </van-field>
      <van-field
        v-if="batchParams.result === '0'"
        v-model="batchParams.refuseReason"
        :label="$t('驳回原因')"
        :placeholder="$t('请填写驳回原因')"
        maxlength="50"
        required
        :rules="[{ required: true }]"
      />
    </van-form>
  </van-dialog>
</div>
</template>
<script>
import SearchBarNew from '@/components/search-bar-new'
import orderServices from '@/services/orderServices'
import loading from '@/utils/loading'
import List from '@/components/baseList/list.vue'
import messages from '@/lang'
export default {
  name: 'orderEditExamine',
  components: {
    SearchBarNew,
    List
  },
  data() {
    return {
      searchStr: '',
      totalCount: 0,
      showDialog: false,
      examineIds: [],
      batchParams: {
        result: '',
        refuseReason: '',
      },
      btnLoading: false,
    }
  },
  computed: {},
  mounted() {},
  methods: {
    // 输入框键入
    onInput(value) {
      this.searchStr = value
    },
    // 刷新
    onRefresh() {
      // 清除分配信息
      this.$refs.list.onRefresh()
      this.getLictCount()
    },
    // 取消
    cleanDistribution() {
      this.$refs.list.list.forEach(item=>{
        item.checked=false
      })
      this.examineIds = []
    },
    // 全选
    onAllChecked() {
      const ids = []
      this.$refs.list.list.forEach(item=>{
        item.checked = true
        ids.push(item.orderId)
      })
      this.examineIds = ids
    },
    // 批量审核
    async onExamine() {
      if (this.examineIds.length == 0) {
        this.$toast(this.$t('请至少选择一条订单！'))
        return false
      }
      if (this.btnLoading) return false
      this.btnLoading = true
      loading.showLoading()
      const res = await orderServices.orderUpdateAuditCheck({ orderIds: this.examineIds, result: 1 })
      loading.hideLoading()
      this.btnLoading = false
      const { code, msg, message } = res
      // 物料失效 或者物料校验不通过
      if ([40012, 40013].includes(code)) {
        this.$dialog.confirm({
          title: this.$t('提示'),
          message: msg||message,
          confirmButtonColor: '#B9921A',
          confirmButtonText: this.$t('立即驳回')
        }).then(async() => {
          const params = {
            orderIds: this.examineIds,
            result: 0,
            refuseReason: msg || message
          }
          this.audit(params)
        }).finally(() => {
        })
      } else if (code == 0) {
        this.showDialog = true
      } else {
        this.$toast.fail(msg || message)
      }
    },
    onClick() {
      let ids = []
      this.$refs.list.list.forEach(item=>{
        if (item.checked){
          ids.push(item.orderId)
        }
      })
      this.examineIds = ids
    },
    // 发起审核
    async audit(params) {
      if (this.btnLoading) return false
      this.btnLoading = true
      loading.showLoading()
      const res = await orderServices.orderUpdateAudit(params)
      this.examineIds = []
      const { code, msg, message } = res
      if (code === 0) {
        this.$toast.success(this.$t('操作成功！'))
        setTimeout(() => {
          this.btnLoading = false
          loading.hideLoading()
          this.$router.go(-1)
        }, 1500)
      } else {
        this.btnLoading = false
        loading.hideLoading()
        this.$toast(msg||message)
      }
    },
    beforeClose(action, done) {
      if (action === 'confirm') {
        this.$refs.dialogForm.validate().then(async() =>{
          if (this.batchParams.result == '1') {
            const params = {
              orderIds: this.examineIds,
              result: 1,
            }
            this.audit(params)
            done()
          } else {
            const params = {
              orderIds: this.examineIds,
              result: 0,
              refuseReason: this.batchParams.refuseReason
            }
            this.audit(params)
            done()
          }
        },done.bind(null,false))
      } else {
        this.batchParams = this.$options.data().batchParams
        done()
      }
    },
    // 获取列表数据
    fetchApi(obj={}) {
      const {
        searchStr
      } = this
      const params = {
        pageNum: obj.currentPage,
        pageSize: obj.pageSize,
        searchStr,
      }
      loading.showLoading()
      return orderServices.orderUpdateList(params).then(res => {
        loading.hideLoading()
        this.totalCount = res.page.totalCount
        res.dataList.forEach(item=>item.checked=false)
        return res
      })
    },
    // 跳转至订单详情
    goDetail(item) {
      this.$router.push({
        path: '/order-edit',
        query: {
          id: item.orderId,
          toExamine: true
        },
      })
    },
  }
}
</script>
<style lang="less" scoped>
.editExamine{
  .totalCount{
    color: @black;
    padding: 10px 16px 0 16px;
    font-size: 12px;
  }
  .editItem{
    display: flex;
    flex-direction: column;
    padding: 16px 12px;
    position: relative;
    .orderStatus-l{
      flex: 1;
      font-size: 16px;
      line-height: 16px;
      color: #323232;
    }
    .check-item{
      position: absolute;
      right: 16px;
      top: 45%;
      z-index: 1;
      /deep/.van-checkbox__icon{
        font-size: 16px;
        .van-icon{
            border: 2px solid rgba(13,23,26,0.45);
        }
      }
      /deep/.van-checkbox__icon--checked .van-icon{
        background-color: #0D171A;
        border-color: #0D171A;
        line-height: 1;
      }
    }
    .amount{
      position: absolute;
      right: 0px;
      font-size: 13px;
      color: #B9921A;
    }
  }
}
</style>
